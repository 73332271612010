<template>
  <v-container>
    <data-table
      :initial-filters="initialFilters"
      entity-name="Transformation"
      entity-name-plural="Transformations"
      api-path="admin/transformations"
      router-namespace="transformations"
      :updateRoute="true"
      :canEdit="() => { return true }"
      :canDestroy="() => { return false }"
      :canCreate="true"
      :headers="headers"
      :item="item"
      :item-template="itemTemplate"
    > 
      <template v-slot:modalForm="slotProps">
        <v-row >
          <v-col
            cols="6"
            sm="12"
          >
            <v-text-field
              v-model="slotProps.slotProps.item.mode"
              label="Mode"
              placeholder="STP"
              autofocus
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            sm="12"
          >
            <v-text-field
              v-model="slotProps.slotProps.item.name"
              label="Name"
              placeholder="Stop processing"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col
            cols="12"
            sm="12"
          >
            <json-field
              v-model="slotProps.slotProps.item.params"
              label="Params"
            ></json-field>
          </v-col>
        </v-row>
      </template>
    </data-table>
  </v-container>
</template>
<script>

import DataTable from '@/components/DataTable'
import JsonField from '@/components/JsonField'

export default {
  components: {
    DataTable,
    JsonField
  },
  data () {
    return { 
      initialFilters: {
        o: '-id',
        page: 1,
        q: '',
        page_size: 50
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Mode',
          align: 'start',
          value: 'mode',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false 
        },
      ],
      item: {
        name: '',
        mode: "",
        params: {},
      },
      itemTemplate: {
        name: '',
        mode: "",
        params: {},
      },
    }
  } 
}
</script>